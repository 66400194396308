<template>
  <div>
    <!-- Is loading -->
    <div class="text-center" v-if="isLoadingIncidentTypes">
      <a-spin />
    </div>
    <!-- / Is loading -->

    <!-- Options wrapper -->
    <div class="options-wrapper" v-if="!isLoadingIncidentTypes">
      <!-- Options -->
      <a-row :gutter="20">
        <a-col
          :span="colSpan"
          v-for="option in sosIncidentTypesSorted"
          :key="option.id"
        >
          <incident-type-option
            :incident-type="option"
            :sos="true"
          ></incident-type-option>
        </a-col>
      </a-row>
      <!-- / Options -->

      <div class="options-separator"></div>

      <!-- Options -->
      <a-row :gutter="20">
        <a-col
          :span="colSpan"
          v-for="option in otherIncidentTypesSorted"
          :key="option.id"
        >
          <incident-type-option
            :incident-type="option"
            :categories="categories"
          ></incident-type-option>
        </a-col>
      </a-row>
      <!-- / Options -->
    </div>
    <!-- / Options wrapper -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
const _ = require("lodash");
import IncidentTypeOption from "./IncidentTypeSelector/IncidentTypeOption.vue";
export default {
  components: { IncidentTypeOption },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters("helpCaller", {
      isLoadingIncidentTypes: "isLoadingIncidentTypes",
      sosIncidentTypes: "sosIncidentTypes",
      otherIncidentTypes: "otherIncidentTypes",
    }),

    sosIncidentTypesSorted() {
      let ranked = _.sortBy(this.sosIncidentTypes, "rank");
      // reverse
      return ranked.reverse();
    },

    otherIncidentTypesSorted() {
      let filtered = _.filter(this.otherIncidentTypes, (f) => {
        return f.incidentTypeGroupId;
      });
      let ranked = _.sortBy(filtered, "rank");
      // reverse
      return ranked.reverse();
    },

    categories() {
      return _.filter(this.otherIncidentTypes, (f) => {
        return !f.incidentTypeGroupId;
      });
    },

    colSpan() {
      return this.windowWidth < 1200 ? 24 : 12;
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
};
</script>
<style scoped lang="scss">
.options-wrapper {
  padding-bottom: 20px;
  .incident-type-option {
    margin-bottom: 15px;
  }
}

.options-separator {
  padding-top: 30px;
}
</style>