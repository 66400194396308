<template>
  <div class="home">
    <div class="page-header">
      <div class="page-title">
        <h1 style="font-size: 30px">Call For Help</h1>
      </div>
      <div class="actions" v-if="!isDemoModeOff">
        <span class="is-demo-label">Demo</span>
        <a-switch :disabled="isDemoModeAlways||isDemoModeNone" v-model="is_demo" />
      </div>
    </div>

    <div class="organisation-selector-wrapper">
      <organisation-selector></organisation-selector>
    </div>

    <div class="incident-type-selector-wrapper" v-if="selectedOrganisation">
      <incident-type-selector></incident-type-selector>
    </div>
  </div>
</template>

<script>
import OrganisationSelector from "./CallForHelp/OrganisationSelector.vue";
import IncidentTypeSelector from "./CallForHelp/IncidentTypeSelector.vue";
import RequiresNonHiddenPresences from "../mixins/RequiresNonHiddenPresences";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Home",
  mixins: [RequiresNonHiddenPresences],
  components: { OrganisationSelector, IncidentTypeSelector },
  computed: {
    ...mapGetters("helpCaller", {
      selectedOrganisation: "selectedOrganisation",
      isDemo: "isDemo",
      isDemoModeOff: "isDemoModeOff",
      isDemoModeNone: "isDemoModeNone",
      isDemoModeAlways: "isDemoModeAlways",
      isDemoModeOptional: "isDemoModeOptional"
    }),

    is_demo: {
      set(val) {
        if (this.isDemoModeOptional) {
          this.setIsDemo(val);
        }
      },
      get() {
        return this.isDemo;
      },
    },
  },
  methods: {
    ...mapActions("helpCaller", {
      setIsDemo: "setIsDemo",
    }),
  },
};
</script>
<style scoped>
.organisation-selector-wrapper {
  margin-top: 30px;
}

.incident-type-selector-wrapper {
  margin-top: 20px;
}

.is-demo-label {
  margin-right: 10px;
}
</style>